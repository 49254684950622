@import '@americastestkitchen/mise/mise-styles/main.scss';

.wrapper {
  @media screen and (min-width: $miseBreakpointLg) {
    max-width: 455px;
  }
}
.header {
  margin: 0 0 $miseSpacingx3;
}

.icon {
  margin-right: $miseSpacingx5;
  height: 14px;
  width: 14px;
  color: $miseColorPrimaryMidBase;
}
.listItem, .subHeader {
  margin-bottom: $miseSpacingx2;

}
