@import '@americastestkitchen/mise/mise-styles/main.scss';

.qrCodeWrapper {
  display: flex;
  gap: $miseSpacingx3;
}
.qrCode {
  display: none;
}
.header {
  padding-bottom: $miseSpacingx3;
  margin: auto;
}
.mediaLinks {
  display: flex;
  gap: $miseSpacingx4;
}
.logos {
  max-height: 42px;
  width: auto;
}

@media screen and (min-width: $miseBreakpointMd) {
  .logos {
    max-height: 53px;
  }
}
@media screen and (min-width: $miseBreakpointLg) {
  .header {
    padding-bottom: $miseSpacingx3;
    margin: auto;
  }
  .qrCode {
    display: initial;
  }
  .header {
    margin: initial;
  }
}