@import '@americastestkitchen/mise/mise-styles/main.scss';

.relativePos {
  position: relative;
  margin: auto;
  @media screen and (min-width: $miseBreakpointMd) {
    max-width: 670px;
  }
  @media screen and (min-width: $miseBreakpointLg) {
    max-width: 980px;
  }
}
.testimonialsWrapper {
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 11px;
  scrollbar-width: none;
  padding: 79px 0;
  @media screen and (min-width: $miseBreakpointMd) {
    padding: 108px 0;
  }
  
  @media screen and (min-width: $miseBreakpointLg) {
    padding: unset;
    height: 412px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  >div:not(:last-child) {
    display: block;
    flex: 0 0 auto;
    scroll-snap-align: start;
    text-align: center;
    width: 100%;
  }
}

// Breadcrumbs
.activeItems {
  height: auto;
  width: 100%;
  position: absolute;
  bottom: 37px;
  right: 0;
  @media screen and (min-width: $miseBreakpointMd) {
    bottom: 30px;
  }
  @media screen and (min-width: $miseBreakpointLg) {
    bottom: 60px;
  }
}
.focusActiveItems {
  display: flex;
  gap: $miseSpacingx2;
  justify-content: center;
  .breadButton {
    background-color: $miseColorNeutralLightTint;
    border: 1px solid $miseColorPrimaryMidTint;
    border-radius: 50%;
    height: 10px;
    max-width: 10px;
    transition: max-width 0.2s ease-in-out, border-radius 0.2s ease-in-out;
    width: 30px;

    &.active {
      background-color: $miseColorPrimaryMidBase;
      border-radius: 10px;
      max-width: 30px;
    }
  }
}
.breadcrumbDesktop {
  display: none;
  @media screen and (min-width: $miseBreakpointLg) {
    display: unset;
  }
}
.breadcrumbMobile {
  @media screen and (min-width: $miseBreakpointLg) {
    display: none;
  }
}