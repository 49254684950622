@import '@americastestkitchen/mise/mise-styles/main.scss';

.hidden {
  @media screen and (min-width: $miseBreakpointLg) {
    display: none !important;
  }
}

.testimonialWrapper {
  width: 100%;
  padding: 0 $miseSpacingx4;
}

.testimonialContent {
  color: $miseColorNeutralDarkShade;
  display: inline-grid;
  gap: 20px;

  // temerary overrides
  .quote {
    @media screen and (min-width: $miseBreakpointMd) {
      font-size: 44px;
      line-height: 48px;
    }

    @media screen and (min-width: $miseBreakpointLg) {
      font-size: 60px;
      line-height: 58px;
    }
  }

  .citation {
    font: $m-f-title-Xsmall;

    @media screen and (min-width: $miseBreakpointMd) {
      font: $m-f-title-small;
    }
  }
}