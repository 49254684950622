@import '@americastestkitchen/mise/mise-styles/main.scss';
.mainPageWrapper {
  background-color: $miseColorPrimaryLightTint;
  max-width: 100vw;
  overflow: hidden;
}
.heroWrapper {
  position: relative;
  margin-bottom: 60px;
  padding-top: $miseSpacingx6;
}
.intro {
  grid-area: intro;
  display: flex;
  align-items: center;
} 
.image {
  grid-area: image;
}
.links {
  grid-area: links;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gridWrapper {
  padding: 0 $miseSpacingx4;
  margin: 0 auto;
  z-index: 1;
} 
.testimonial {
  padding: $miseSpacingx6 0;
  background-color: $miseColorPrimaryLightBase;
  border-top: 1px solid $miseColorPrimaryLightShade;
  height: 289px;
  position: relative;
}

@media screen and (min-width: $miseBreakpointMd) {
  .gridWrapper {
    max-width: 500px;
  }
}

@media screen and (min-width: $miseBreakpointLg) {
  .heroWrapper {
    margin-bottom: unset;
  }
  .testimonial {
    border-top: initial
  }
  .links {
    justify-content: initial;
  }
  
  .gridWrapper {
    display: grid;
    max-width: 1136px;
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    'intro image'
    'links image';
  }
  .eclipseDecorator {
    background-color: $miseColorPrimaryLightBase;
    border: 1px solid $miseColorPrimaryLightShade;
    border-bottom: unset;
    border-radius: 50% / 100% 100% 0% 0%;
    height: 295px;
    left: 50%;
    position: absolute;
    bottom: 0;
    transform: translateX(-50%);
    width: calc(100vw + 303px);
    min-width: 1614px;
    overflow: hidden;
  }
}
