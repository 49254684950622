@import '@americastestkitchen/mise/mise-styles/main.scss';

.background {
  padding: 0 $miseSpacingx4;
  background-color: $miseColorPrimaryLightBase;
}

.qrCode {
  display: none;
}

.wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 60px 0;

}

.bodyWrapper {
  align-content: center;
}

.header {
  margin: auto;
  padding-bottom: $miseSpacingx3;
}
.mediaLinks {
  display: flex;
  grid-gap: $miseSpacingx2;
}

.logos {
  max-height: 53px;
  width: auto;
}


@media screen and (min-width: $miseBreakpointLg) {
  .header {
    margin: auto;
    padding-bottom: unset;
  }

  .qrCode {
    padding: $miseSpacingx4 0;
    justify-content: center;
    display: flex;
    margin: auto;
  }

  .logos {
    max-height: 35px;
  }
}