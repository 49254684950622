@import '@americastestkitchen/mise/mise-styles/main.scss';

.heroImage {
  max-height: 680px;
  margin: auto;
  display: block;
  padding-bottom: $miseSpacingx6;
}

@media screen and (min-width: $miseBreakpointMd) {
  .heroImage {
    max-height: 562px;
    padding-bottom: $miseSpacingx10;
  }
}

@media screen and (min-width: $miseBreakpointLg) {
  .heroImage {
    max-height: 719px;
    display: initial;
    padding-bottom: initial;
  }
}
 