@import '@americastestkitchen/mise/mise-styles/main.scss';

.testimonialHeader {
  width: 100%;
  text-align: center;
  padding-bottom: $miseSpacingx6;
}

.testimonialCardsWrapper {
  height: 170px;
}

.testimonialCardsWrapper {
  padding: 0 $miseSpacingx6;
  display: grid;
  gap: $miseSpacingx6;
  overflow-x: auto;
  scrollbar-width: none;
  grid-template-columns: 1fr 1fr 1fr;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }

  .body {
    text-align: left;
    background-color: $miseColorNeutralLightTint;
    padding: $miseSpacingx4;
    scroll-snap-align: start;
    width: 315px;
    height: 160px;
    border-radius: $miseSpacingx2;
    border: 1px solid #F8D6C7;
    box-shadow: 2px 2px 6px 0px rgba(44, 44, 44, 0.08);
  }
}

.ratings {
  margin-bottom: $miseSpacingx4;
  gap: $miseSpacingx1;
  display: flex;
  .star {
    width: 10px;
    height: 10px;
  }
}


@media screen and (min-width: $miseBreakpointLg) {
  .testimonialCardsWrapper {
    padding: initial;
  }
  .testimonialGrid {
    max-width: 1005px;
    margin-inline: auto;
  }
}